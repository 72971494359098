import React, { useState, useEffect } from "react";
import "./ListCourses.scss"
import { Image, Item, List } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { map } from "lodash";
import { BASE_PATH } from "../../../utils/constants";
export function ListCourses(props) {
    const { listCourses, language } = props;
    //console.log(listCourses)
    return (
        <Item className="course-container">
            {map(listCourses, (course) => (

                <div key={course._id} className="course-container-div">
                    <Item.Image src={`${BASE_PATH}${course.image[0].url}`} className="course-container-div__image" />
                    {/* {console.log(course)} */}
                    <Item.Content>
                        <Item.Header as='a' className="course-container-div__title">{course.title}</Item.Header>
                        <Item.Meta className="course-container-div__date">{course.date}</Item.Meta>
                        <Item.Description className="course-container-div__from">
                            {course.from}
                        </Item.Description>
                        <Item.Extra className="course-container-div__credential"><a href={`${course.credential}`}>{language == "en" ? "Credential" : "Credenciales"}</a></Item.Extra>

                    </Item.Content>
                </div>


            ))}


        </Item>


    );
}

// import React, { useState } from "react";
// import "./ListCourses.scss";
// import { Image, Item, List, Pagination } from "semantic-ui-react";
// import { map } from "lodash";
// import { BASE_PATH } from "../../../utils/constants";

// export function ListCourses(props) {
//     const { listCourses, language } = props;
//     console.log("list courses = ", listCourses)
//     const coursesPerPage = 8; // Número de cursos por página
//     const [currentPage, setCurrentPage] = useState(1);

//     // Calcular el índice de inicio y fin de los cursos a mostrar en la página actual
//     const startIndex = (currentPage - 1) * coursesPerPage;
//     const endIndex = startIndex + coursesPerPage;
//     const coursesToDisplay = listCourses.slice(startIndex, endIndex);

//     const totalPages = Math.ceil(listCourses.length / coursesPerPage);

//     const handlePageChange = (e, { activePage }) => {
//         setCurrentPage(activePage);
//     };

//     return (
//         <div>
//             <Item className="course-container">
//                 {map(coursesToDisplay, (course) => (
//                     <div key={course._id} className="course-container-div">
//                         {/* ... (resto de tu código) ... */}
//                     </div>
//                 ))}
//             </Item>

//             {/* Paginación de Semantic UI */}
//             {totalPages > 1 && (
//                 <Pagination
//                     activePage={currentPage}
//                     totalPages={totalPages}
//                     onPageChange={handlePageChange}
//                 />
//             )}
//         </div>
//     );
// }
