import React from "react";
import { Card, Icon } from "semantic-ui-react";
import "./Contact.scss"
import { useState, useEffect } from "react";
import { getAboutUsApi } from "../../../api/user";
import { ContactForm } from "../../../Components/Contact/ContactForm";
export function Contact(props) {
    const { language } = props;
    const [aboutMe, setAboutMe] = useState(null)
    useEffect(() => {
        (async () => {
            const response = await getAboutUsApi();
            setAboutMe(response);
            console.log("hey", aboutMe)
        })();
    }, []);
    return (
        <div className="contact-container">
            <div className="contact-container_title"><h1>{language == "en" ? "Get in touch:" : "Contacto:"}</h1></div>
            <div className="contact-container_info">
                <div className="contact-container_info_1">
                    <div className="contact-container_info_icon">
                        <Icon name="map marker alternate" size="huge" />
                    </div>
                    <div>{aboutMe ? (<p>{aboutMe[0].adress}</p>) : null}</div>
                </div >
                <div className="contact-container_info_2">
                    <div className="contact-container_info_icon">
                        <Icon name="phone" size="huge" />
                    </div>
                    <div>{aboutMe ? (<p>{aboutMe[0].phoneNumber}</p>) : null}</div>

                </div>
                <div className="contact-container_info_3">
                    <div className="contact-container_info_icon">
                        <Icon name="at" size="huge" />
                    </div>
                    <div>{aboutMe ? (<p>{aboutMe[0].mail}</p>) : null}</div>

                </div>
            </div>

            <div className="contact-container_info2">
                <div className="contact-container_info2_1">
                    <div className="contact-container_info2_icon">
                        <Icon name="bitbucket" size="huge" />
                    </div>
                    <div>{aboutMe ? (<a href={aboutMe[0].bitbucket}><p>https://bitbucket.com</p></a>) : null}</div>
                </div >
                <div className="contact-container_info2_2">
                    <div className="contact-container_info2_icon">
                        <Icon name="github" size="huge" />
                    </div>
                    <div>{aboutMe ? (<a href={aboutMe[0].github}><p>https://github.com</p></a>) : null}</div>
                </div >
                <div className="contact-container_info2_3">
                    <div className="contact-container_info2_icon">
                        <Icon name="linkedin" size="huge" />
                    </div>
                    <div>{aboutMe ? (<a href={aboutMe[0].linkedin}><p>https://linkedin.com</p></a>) : null}</div>

                </div>

            </div>
            <div className="contact-container_form">
                <ContactForm />
            </div>
        </div>

    )
}