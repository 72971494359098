import React, { useState, useEffect } from "react";
import { Sphere } from "../../../Components/Sphere/Sphere";
import "./Courses.scss";
import { getLanguages } from "../../../api/getLanguages";
import { getPrograms } from "../../../api/getPrograms";
import { Languages } from "../../../Components/Courses/Languages/Languages";
import { ListCourses } from "../../../Components/Courses/ListCourses/ListCourses";
import { getCourses } from "../../../api/getCourses";
import { Studies } from "../../../Components/Courses/Studies/Studies";
import { getStudies } from "../../../api/getStudies";
import { map } from "lodash";

export function Courses(props) {
  const [texts, setTexts] = useState(null);
  const [courses, setCourses] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [studies, setStudies] = useState(null);

  const { language } = props;
  useEffect(() => {
    (async () => {
      const response = await getPrograms();
      setTexts(response);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getLanguages(language);
      setLanguages(response);
    })();
  }, [language]);

  useEffect(() => {
    (async () => {
      const response = await getStudies(language);
      setStudies(response);
      console.log(studies);
    })();
  }, [language]);

  useEffect(() => {
    (async () => {
      const response = await getCourses();
      setCourses(response);
    })();
  }, []);

  return (
    <div className="skills-container">
      <div className="skills-container-div">
        <div className="skills-container-div__languages__title">
          <h1>
            {language == "en" ? "Languages Speaking:" : "Idiomas que hablo:"}
          </h1>
        </div>
        <div className="skills-container-div__languages__speaks">
          <Languages languages={languages} />
        </div>
        <div className="skills-container-div__program__title">
          <h1>
            {language == "en"
              ? "Programming Languages:"
              : "Lenguajes de programación:"}
          </h1>
        </div>
        <div className="skills-container-div__programming">
          {texts ? <Sphere texts={texts} /> : null}
        </div>
        <div className="skills-container-div__studies">
          <h1>{language == "en" ? "Studies:" : "Estudios:"}</h1>
        </div>
        <div className="skills-container-div__studies_content">
          {map(studies, (study) => (
            <div key={study._id} className="item">
              <Studies study={study} />
            </div>
          ))}
        </div>
        <div className="skills-container-div__courses__title">
          <h1>{language == "en" ? "Courses:" : "Cursos"}</h1>
        </div>
        <div className="skills-container-div__courses__content">
          {texts ? (
            <ListCourses listCourses={courses} language={language} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
